<template>
    <div class="courseFilter-wrap mb-5">
        <h2>Filter Course By</h2>
        <div class="display-card">
            <label class="filter-title">Search</label>
            <input type="text" placeholder="Type and press Enter" class="univ-input mb-4" v-model="filterData.keywords" @change="filters">

            <label class="filter-title"> Course Category  </label>
            <vue-scroll :ops="categScroll">
                <ul class="mb-4">
                    <li>
                        <div class="univRadio">
                            <input id="all" type="radio" v-model="filterData.category" value="all" @change="filters" checked="checked" >
                            <label for="all" class="univRadio-label">All</label>
                        </div>
                    </li>
                    <li v-for="(item,i) in categories" :key="i" >
                        <div class="univRadio">
                            <input :id="'category'+i" type="radio" v-model="filterData.category" :value="item.slug" @change="filters" >
                            <label :for="'category'+i" class="univRadio-label"> {{ item.title }} </label>
                        </div>
                    </li>
                </ul>
            </vue-scroll>
            <label class="filter-title"> Course Level </label>
            <ul>
                <li>
                    <div class="univRadio">
                        <input id="all-level" type="radio" v-model="filterData.level" value="all" @change="filters" checked="checked" >
                        <label for="all-level" class="univRadio-label">All</label>
                    </div>
                </li>
                <li v-for="(item,j) in levels" :key="j" >
                    <div class="univRadio">
                        <input :id="'level'+j" type="radio" v-model="filterData.level" :value="item.slug" @change="filters" >
                        <label :for="'level'+j" class="univRadio-label"> {{ item.title }} </label>
                    </div>
                </li>
            </ul>

        </div>
    </div>
</template>
<script>
import axios from 'axios'
import api from '@/services/api'
export default {
    name: 'CourseFilter',
    data: () => ({
        categScroll: {
            vuescroll: {
                mode: "native",
                wheelScrollDuration: "700",
            },
            scrollPanel: {
                maxHeight: 200,
            },
            bar: {
                background: "#ddd",
            },
        },
        categories: [],
        levels: [],
        languages: [],

        filterData:{
            page:1,
            limit: 3,
            keywords:"",
            level:"all",
            category:"all",
            sort:"latest",
        },
    }),
    mounted() {
        let app = this;
        app.renderData();
    },
    methods: {
        async renderData(){
            let app = this;
            await axios.get(api.getUrl('/course/common')).then(function (response) {
                let responseData    = response.data.data;
                app.categories      = responseData.categories;
                app.levels          = responseData.levels;
                app.languages       = responseData.languages;
            });
        },
        filters(){
            let app = this;
            if(app.filterData.category == 'all'){
                app.filterData.page = 1;
            }
            if(app.filterData.level == 'all'){
                app.filterData.page = 1;
            }
            app.$root.$emit("filter-courses", app.filterData);
        },
    }
  }
</script>